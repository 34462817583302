<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸联合创始人李佐凡受邀出席复旦DS&BA项目座谈会
              </p>
              <div class="fst-italic mb-2">2023年8月24日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/55/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    8月17日，非凸科技联合创始人&CTO李佐凡受邀参加复旦管院数据科学与商业分析专业硕士（DS&BA）项目发展座谈会，与学校教授、老师在生源背景、课程教学、职业发展、学生培养和企业合作方面进行深入交流，旨在更好地提升和发展DS&BA项目，帮助同学们做好职业生涯规划。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/55/02-李佐凡.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在2022-2023第二届D-Lab实践项目（DS&BA项目培养中的必修环节）中，职业导师李佐凡带领同学们完成了为期3个月的项目实习，综合运用机器学习、深度学习等前沿技术对算法策略问题进行了深入探索，既解决了业界的实际问题，又增强了学生的实践能力以及丰富了企业自身的研究体系。非凸科技将继续参与2023-2024 D-Lab项目，与院校导师共同指导学生完成实践课题，为学生的优质就业提供有力保障。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/55/03-奖杯.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技自成立以来，不仅致力于技术的创新变革，还重视人才的引进与培养，注重人才梯队的建设。未来，非凸科技还将与多所高校在联合培养、共同研究、专业共建等方面展开深度合作，培养创新型、复合型高素质数智交易人才。
                  </p>
                  <p>
                    期待同学们的加入，共同探索科技赋能投资的星辰与大海。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News55",
};
</script>

<style></style>
